.navbar-container {
  .navbar {
    padding: 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &__logout-btn {
      color: $black;
      background: transparent;
      font-weight: 600;
      border: 0;
      padding: 10px 0 10px 25px;
      font-size: 0.95rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .navbar-nav {
      a {
        color: $black;
        font-weight: 600;
        font-size: 0.95rem;
        padding: 10px 25px;

        + svg {
          display: none;
        }

        @media (min-width: 992px) {
          &.active + svg {
            display: block;
          }
        }
      }
    }
  }
}
