.main-wrapper {
  height: 100%;
  background-color: white;

  // min-height: calc(80.5vh - 50px);

  .sub-nav {
    padding: 40px 0;
    background-color: $bg-gray;

    .back-btn {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}
