.login-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .login-header {
    padding-top: 20px;
    font-weight: 700;
  }

  .bottom-banner {
    width: 100%;
    max-width: 800px;
    height: 110px;
    background-color: #cfab7a;
  }
}
