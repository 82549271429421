.typeahead {
  input {
    border: 1px solid black;
    border-radius: 0;
    padding: 30px;
  }

  .rbt-menu-custom-option {
    background-color: $primary-btn;
    color: white;
    padding: 20px;
  }
}
