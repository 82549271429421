.button {
  border: 0;
  background: transparent;
  margin: 0;

  &--dark-blue {
    color: white;
    background-color: $primary-btn;
  }

  &--red {
    color: white;
    background-color: #b91c1c;
  }

  &--gray {
    background-color: #98a2ac;
    color: white;
  }

  &--small {
    font-size: 0.85rem;
  }

  &--link {
    background: transparent;
    padding: 0;
    margin: 0;
    text-align: left;
    border: 0;
    color: #4582ec;
    cursor: pointer;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }
}
