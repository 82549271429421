.footer {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  align-items: center;

  a {
    font-weight: 600;
  }
}
